import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";
import img1 from "images/founder1.png";
import img2 from "images/founder2.png";
export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Cameron Esgate`,
    job: "CEO head of guest relations ",
    avatar: img1,
  },
  {
    id: "4",
    name: `Kristen Esgate `,
    job: "Head of Fleet management and Expansion ",
    avatar: img2,
  },
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative mb-16 lg:mb-28">
      <h2 className="text-3xl font-bold mb-6 text-center">Founders</h2>
      <p className="text-xl mb-12 lg:mr-12 text-[#9CA3AF] text-center">
        Cameron and Kristen Esgate launched Driven Enterprises with a shared
        passion for providing top-notch car rental services. Their dedication
        and innovative approach have quickly made it the fastest-growing car
        rental business in Port St. Lucie.
      </p>
      <div className="grid sm:grid-cols-2  gap-y-8 lg:grid-cols-2 ">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm mx-auto text-center">
            <div className="relative w-48 h-48 lg:w-96 lg:h-96  rounded-xl overflow-hidden mx-auto">
              <NcImage
                className="object-cover w-full h-full"
                src={item.avatar}
              />
            </div>
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
