import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "Husband and Wife",
    subHeading:
      "Started by a husband and wife team with just 2 cars.",
  },
  {
    id: "2",
    heading: "Fastest Growing",
    subHeading: "One of the fastest growing rental companies in the Treasure Coast.",
  },
  {
    id: "3",
    heading: "The Name",
    subHeading:
      "The name Driven comes from the owners drive to provide excellent customer service and products. ",
  },
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
     <h2 className="text-3xl font-bold mb-6">Fast Facts</h2>
     <p className="text-xl mb-20 text-[#9CA3AF]">
     3 fast facts about Driven Enterprises
      </p>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8 ">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 rounded-2xl dark:border-[#374151] border-2 bg-[#141414]"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic;
