"use client";

import { MapPinIcon } from "@heroicons/react/24/outline";
import { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import LatLng from "models/Latlng";
import toast from "react-hot-toast";
import { useData } from "data/data-provider";
import { getNearbyLocations, searchLocations } from "api/locations/locations";
import LocationModal from "./LocationModal";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  sameDropOffLocation?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Location",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  sameDropOffLocation,
}) => {
  const {
    ourLocation,
    setPickupLocation,
    searchedPickupLoction,
    setSearchedPickupLocation,
    pickupLocation,
    ourCompany,
  } = useData();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchedLocations, setSearchedLocations] = useState<any>();
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [locationDataLoading, setLocationDataLoading] = useState<boolean>(true);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);

  const callSearchLocationsAPI = async (input: string) => {
    try {
      setLocationDataLoading(true);
      const data = (await searchLocations(input)) as any;
      setSearchedLocations(data.predictions);
    } catch (error: any) {
      toast.error(error.message); // Or handle errors in a more sophisticated way
    } finally {
      setLocationDataLoading(false);
    }
  };

  useEffect(() => {
    callSearchLocationsAPI(searchedPickupLoction);
  }, [searchedPickupLoction]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);
  
  useEffect(() => {
    if (pickupLocation === null) {
      setSearchedPickupLocation(""); // Clear state
      if (inputRef.current) {
        inputRef.current.value = ""; // Clear the input field display
      }
    }
  }, [pickupLocation, setSearchedPickupLocation]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    setShowPopover(false);
  };

  const handleSelectLocation = (item: any) => {
    setSearchedPickupLocation("");
    setPickupLocation(null);
    if (item.radius) {
      setSelectedLocation(item);
      setShowLocationModal(true);
    } else {
      setPickupLocation(item);
      setShowPopover(false);
    }
  };

  const handleSelectSearchedLocation = async (item: any) => {
    const position = (await checkLocationInRange(
      item.place_id
    )) as LatLng | null;

    let minDistanceDeliveryLocation: any = null;
    let minDistance = Infinity;
    let locationsNearUser = [];

    if (position) {
      for (const location of ourLocation ?? []) {
        if (location.radius !== null) {
          const startLatLng = {
            latitude: Number(location.latitude),
            longitude: Number(location.longitude),
          };

          const endLatLng = {
            latitude: Number(position.latitude),
            longitude: Number(position.longitude),
          };

          let distance = calculateDistance(startLatLng, endLatLng);
          if (ourCompany?.distance_unit === "miles") {
            distance /= 1.609344; // Convert km to miles if the unit is miles
          }
          if (distance <= location.radius) {
            if (minDistanceDeliveryLocation) {
              if (minDistance > distance) {
                minDistanceDeliveryLocation = location;
                minDistance = distance;
              }
            } else {
              minDistanceDeliveryLocation = location;
              minDistance = distance;
            }
          }
        }
      }
    }

    if (minDistanceDeliveryLocation) {
      locationsNearUser.push(minDistanceDeliveryLocation);
      setPickupLocation(minDistanceDeliveryLocation);
      // if (sameDropOffLocation === true) {
      //   dropOffLocationExtras?.options.map((location) => {
      //     if (location.title === minDistanceDeliveryLocation?.name) {
      //       setSelectedDropOffLocationExtra(location);
      //     }
      //   });
      // }
      setSearchedPickupLocation(item.description);
      setShowPopover(false);
    } else {
      toast.error("Sorry, not yet available! Search for a nearby location.");
    }
  };

  async function checkLocationInRange(placeId: string): Promise<LatLng | null> {
    try {
      const placeDetailsResponse = (await getNearbyLocations(placeId)) as any;

      if (placeDetailsResponse.result) {
        const lat = placeDetailsResponse.result.geometry.location.lat;
        const lng = placeDetailsResponse.result.geometry.location.lng;
        return {
          latitude: lat,
          longitude: lng,
        };
      } else {
        toast.error(`Place Details API error: ${placeDetailsResponse.status}`);
      }
    } catch (error) {
      toast.error("Error fetching location details:" + error);
    }

    return null;
  }

  function radians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  function calculateDistance(start: LatLng, end: LatLng): number {
    const earthRadius = ourCompany?.distance_unit === "miles" ? 3958.8 : 6371; // Earth's radius in meters

    const lat1 = radians(start.latitude);
    const lon1 = radians(start.longitude);
    const lat2 = radians(end.latitude);
    const lon2 = radians(end.longitude);

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c;

    return distance;
  }

  const renderRecentSearches = () => {
    return (
      <div className="mt-2">
        {ourLocation
          .filter(
            (item) =>
              item.active !== false &&
              (item.location_type === "pick_up" ||
                item.location_type === "both")
          )
          .sort((a, b) => a.price - b.price)
          .map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
                  key={item.id}
                  className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-primary-200 cursor-pointer justify-between"
            >
              <div className="flex">
                <span className="block text-neutral-400">
                  <MapPinIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                </span>
                <span className="text-left block font-medium text-neutral-700 dark:text-neutral-200 mx-2">
                  {item.address}
                </span>
              </div>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item.price === 0 ? "Free" : "$" + item.price + "/trip"}
              </span>
            </span>
          ))}
      </div>
    );
  };

  const renderSearchValue = () => {
    return (
      <>
        {ourLocation
          .filter(
            (item) =>
              (item.radius === null || item.radius === 0) &&
              item.active !== false &&
              (item.location_type === "pick_up" ||
                item.location_type === "both") &&
              item.name
                .toLowerCase()

                .includes(searchedPickupLoction.toLowerCase())
          )
          .map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
                key={item.id}
                className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-primary-100 cursor-pointer justify-between"
            >
              <div className="flex">
                <span className="block text-neutral-400">
                  <MapPinIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                </span>
                <span className="text-left block font-medium text-neutral-700 dark:text-neutral-200 mx-2">
                  {item.address}
                </span>
              </div>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item.price === 0 ? "Free" : "$" + item.price + "/trip"}
              </span>
            </span>
          ))}
        {searchedLocations?.map((item: any, index: number) => (
          <span
            onClick={() => handleSelectSearchedLocation(item)}
            key={index}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-primary-100 cursor-pointer justify-between"
          >
            <div className="flex">
              <span className="block text-neutral-400">
                <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="text-left block font-medium text-neutral-700 dark:text-neutral-200 mx-2">
                {item["description"]}
              </span>
            </div>
          </span>
        ))}
      </>
    );
  };

  const handleClear = () => {
    setSearchedPickupLocation("");
    setPickupLocation(null);
    setShowPopover(false);
    if (inputRef.current) {
      inputRef.current.value = ""; // Reset the input field's display
    }
  };
  
  console.log(searchedPickupLoction.length);
  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={
              searchedPickupLoction.length > 0
                ? searchedPickupLoction
                : pickupLocation?.address
            }
            autoFocus={showPopover}
            onChange={(e) => {
              setSearchedPickupLocation(e.currentTarget.value);
            }}
            ref={inputRef}
            readOnly
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{desc}</span>
          </span>
          {(searchedPickupLoction || pickupLocation) && showPopover && (
           <ClearDataButton
           onClick={handleClear}
         />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-transparent ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover &&
        ((searchedPickupLoction && searchedLocations?.length > 0) ||
          ourLocation.filter(
            (item) =>
              (item.radius === null || item.radius === 0) &&
              item.active !== false &&
              (item.location_type === "pick_up" ||
                item.location_type === "both")
          ).length > 0) && (
            <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-[#140B0B] border border-[#E5E7EB] top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto hide-scrollbar">
            {renderRecentSearches()}
          </div>
        )}
      <LocationModal
        isOpen={showLocationModal}
        selectedLocation={selectedLocation}
        onClose={() => setShowLocationModal(false)}
        ourCompany={ourCompany}
        setSearchedPickupLocation={setSearchedPickupLocation}
        setPickupLocation={setPickupLocation}
        ourLocation={ourLocation}
      />
    </div>
  );
};

export default LocationInput;
